import Icon from '@components/_atoms/Icon';
import { ButtonType, TypoVariant } from '@constants/atoms';
import { ButtonColor, IconSize } from '@constants/atoms';
import styled from '@emotion/styled';
import { useGetButtonColor } from '@hooks/atoms/useGetButtonColor';
import { Radius } from '@styles/Radius';
import { MOBILE_MODE } from '@constants/size';
import { useIsMobile } from '@hooks/responsive/useIsMobile';
import Typography from '../Typography';
import { RefObject } from 'react';

type Props = Styleable & {
  readonly width?: string;
  readonly type?: ButtonType;
  readonly color?: ButtonColor;
  readonly text?: string;
  readonly disabled?: boolean;
  readonly arrow?: boolean;
  readonly onClick?: () => void;
  readonly buttonRef?: RefObject<HTMLButtonElement>;
};

type CssProps = {
  readonly arrow: boolean;
  readonly background: string;
  readonly textColor: string;
  readonly borderColor: string;
  readonly hoverBackgroundColor: string;
  readonly hoverTextColor: string;
  readonly hoverBorderColor: string;
};

const ButtonLayout = styled.button<CssProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
  padding: ${({ arrow }) => (arrow ? '12px 15px 12px 23px' : '12px 23px')};
  border-radius: ${Radius.MEDIUM};
  border: 1px solid;
  cursor: pointer;

  background-color: ${({ background }) => background};
  color: ${({ textColor }) => textColor};
  border-color: ${({ borderColor }) => borderColor};

  &:hover {
    background-color: ${({ hoverBackgroundColor }) => hoverBackgroundColor};
    color: ${({ hoverTextColor }) => hoverTextColor};
    border-color: ${({ hoverBorderColor }) => hoverBorderColor};
  }

  &:disabled {
    cursor: not-allowed;
  }

  /* mobile -> small */
  @media ${MOBILE_MODE} {
    padding: ${({ arrow }) => (arrow ? '9px 7px 9px 15px' : '9px 15px')};
  }
`;
const ArrowIcon = styled(Icon)``;
const Text = styled(Typography)``;

export default function Button({
  width,
  type = ButtonType.Submit,
  style,
  color = ButtonColor.ContainedBlue,
  text,
  disabled = false,
  arrow = false,
  onClick,
  buttonRef,
}: Props) {
  const [background, textColor, borderColor, hoverBackgroundColor, hoverTextColor, hoverBorderColor] =
    useGetButtonColor(color, disabled);
  const isMobile = useIsMobile();

  return (
    <ButtonLayout
      style={{ ...style, width }}
      ref={buttonRef}
      type={type}
      arrow={arrow}
      disabled={disabled}
      background={background}
      textColor={textColor}
      borderColor={borderColor}
      hoverBackgroundColor={hoverBackgroundColor}
      hoverTextColor={hoverTextColor}
      hoverBorderColor={hoverBorderColor}
      onClick={onClick}>
      <Text style={{ whiteSpace: 'nowrap' }} variant={isMobile ? TypoVariant.SH4 : TypoVariant.SH3} color={textColor}>
        {text}
      </Text>
      {arrow && <ArrowIcon name="arrow-right" fill={background} stroke={textColor} size={IconSize.SMALL} />}
    </ButtonLayout>
  );
}
